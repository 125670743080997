import * as Localization from 'expo-localization';

export const getRegion = async () => {
  let region;

  const domain = window.location.hostname;
  switch (domain) {
    case 'my.tgi.live':
      region = 'MY';
      break;
    case 'sg.tgi.live':
      region = 'SG';
      break;
    default:
      break;
  }
  if (region) return region;

  switch (Localization.timezone) {
    case 'Asia/Kuala_Lumpur':
      region = 'MY';
      break;
    case 'Asia/Kuching':
      region = 'MY';
      break;
    case 'Asia/Singapore':
      region = 'SG';
      break;
    default:
      region = 'SG';
      break;
  }
  return region;
};
