import React, { useContext } from 'react';
import { StyleSheet, TouchableOpacity, Linking } from 'react-native';
import { useLinkTo } from '@react-navigation/native';
import { Block, Text, theme } from 'galio-framework';

import { useAmplifyAuthProvider } from '../lib/amplify';
import Icon from './Icon';
import argonTheme from '../constants/Theme';

const DrawerItem = (props) => {
  const authProvider = useAmplifyAuthProvider();
  const linkTo = useLinkTo();

  const renderIcon = () => {
    const { title, focused } = props;

    switch (title) {
      case 'Home':
        return (
          <Icon
            name="shop"
            family="ArgonExtra"
            size={14}
            color={focused ? 'white' : argonTheme.COLORS.PRIMARY}
          />
        );
      case 'Explore':
        return (
          <Icon
            name="search-zoom-in"
            family="ArgonExtra"
            size={14}
            color={focused ? 'white' : argonTheme.COLORS.PRIMARY}
          />
        );
      case 'Elements':
        return (
          <Icon
            name="map-big"
            family="ArgonExtra"
            size={14}
            color={focused ? 'white' : argonTheme.COLORS.ERROR}
          />
        );
      case 'Articles':
        return (
          <Icon
            name="spaceship"
            family="ArgonExtra"
            size={14}
            color={focused ? 'white' : argonTheme.COLORS.PRIMARY}
          />
        );
      case 'Profile':
        return (
          <Icon
            name="chart-pie-35"
            family="ArgonExtra"
            size={14}
            color={focused ? 'white' : argonTheme.COLORS.WARNING}
          />
        );
      case 'Account':
        return (
          <Icon
            name="calendar-date"
            family="ArgonExtra"
            size={14}
            color={focused ? 'white' : argonTheme.COLORS.INFO}
          />
        );
      case 'Getting Started':
        return (
          <Icon
            name="spaceship"
            family="ArgonExtra"
            size={14}
            color={focused ? 'white' : 'rgba(0,0,0,0.5)'}
          />
        );
      case 'Contact Us':
        return (
          <Icon
            name="chat-round"
            family="Galio"
            size={14}
            color={focused ? 'white' : argonTheme.COLORS.INFO}
          />
        );
      case 'Log Out':
        return (
          <Icon
            name="log-out"
            family="Galio"
            size={14}
            color={focused ? 'white' : argonTheme.COLORS.WARNING}
          />
        );
      case 'Login':
        return (
          <Icon
            name="log-in"
            family="Galio"
            size={14}
            color={focused ? 'white' : argonTheme.COLORS.WARNING}
          />
        );
      default:
        return null;
    }
  };

  const { focused, title, navigation } = props;

  const containerStyles = [
    styles.defaultStyle,
    focused ? [styles.activeStyle, styles.shadow] : null,
  ];

  return (
    <TouchableOpacity
      style={{ height: 60 }}
      onPress={async () => {
        switch (title) {
          case 'Home':
            linkTo('/home');
            break;
          case 'Explore':
            linkTo('/explore');
            break;
          case 'Contact Us':
            linkTo('/contact-us');
            break;
          case 'Log Out':
            await authProvider.logout()
            break;
          default:
            navigation.navigate(title)
            break;
        }
      }}
    >
      <Block flex row style={containerStyles}>
        <Block middle flex={0.1} style={{ marginRight: 5 }}>
          {renderIcon()}
        </Block>
        <Block row center flex={0.9}>
          <Text
            size={15}
            bold={focused ? true : false}
            color={focused ? 'white' : 'rgba(0,0,0,0.5)'}
          >
            {title}
          </Text>
        </Block>
      </Block>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  defaultStyle: {
    paddingVertical: 16,
    paddingHorizontal: 16,
  },
  activeStyle: {
    backgroundColor: argonTheme.COLORS.ACTIVE,
    borderRadius: 4,
  },
  shadow: {
    shadowColor: theme.COLORS.BLACK,
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 8,
    shadowOpacity: 0.1,
  },
});

export default DrawerItem;
