// local imgs
const Onboarding = require('../assets/imgs/bg.png');
const Icon = require('../assets/imgs/icon.png');
const Logo = require('../assets/imgs/logo.png');
const LogoOnboarding = require('../assets/imgs/logo-onboarding.png');
const ProfileBackground = require('../assets/imgs/profile-screen-bg.png');
const RegisterBackground = require('../assets/imgs/register-bg.png');
const LoginBackground = require('../assets/imgs/login-bg.png');
const Pro = require('../assets/imgs/getPro-bg.png');
const ArgonLogo = require('../assets/imgs/argonlogo.png');
const iOSLogo = require('../assets/imgs/ios.png');
const androidLogo = require('../assets/imgs/android.png');
const Video = require('../assets/imgs/video.png');
const Facebook = require('../assets/imgs/facebook.png');
const Messenger = require('../assets/imgs/messenger.png');
const Whatsapp = require('../assets/imgs/whatsapp.png');
const Avatar = require('../assets/imgs/avatar.png');
const PopupStart = require('../assets/imgs/popup-start.png');
const PopupSurvey = require('../assets/imgs/popup-survey.png');
// internet imgs

const ProfilePicture =
  'https://images.unsplash.com/photo-1492633423870-43d1cd2775eb?fit=crop&w=1650&q=80';

const Viewed = [
  'https://images.unsplash.com/photo-1501601983405-7c7cabaa1581?fit=crop&w=240&q=80',
  'https://images.unsplash.com/photo-1543747579-795b9c2c3ada?fit=crop&w=240&q=80',
  'https://images.unsplash.com/photo-1551798507-629020c81463?fit=crop&w=240&q=80',
  'https://images.unsplash.com/photo-1470225620780-dba8ba36b745?fit=crop&w=240&q=80',
  'https://images.unsplash.com/photo-1503642551022-c011aafb3c88?fit=crop&w=240&q=80',
  'https://images.unsplash.com/photo-1482686115713-0fbcaced6e28?fit=crop&w=240&q=80',
];

const Products = {
  'View article':
    'https://images.unsplash.com/photo-1501601983405-7c7cabaa1581?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=840&q=840',
};

export default {
  Onboarding,
  Icon,
  Logo,
  LogoOnboarding,
  ProfileBackground,
  ProfilePicture,
  RegisterBackground,
  LoginBackground,
  Viewed,
  Products,
  Pro,
  ArgonLogo,
  iOSLogo,
  androidLogo,
  Video,
  Facebook,
  Messenger,
  Whatsapp,
  Avatar,
  PopupStart,
  PopupSurvey,
};
