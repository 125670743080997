import React from 'react';
import PropTypes from 'prop-types';
import {
  StyleSheet,
  Dimensions,
  Image,
  View,
  Text,
} from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { Block, theme } from 'galio-framework';
import LinkOpacity from './LinkOpacity';

import { argonTheme } from '../constants';
import Images from '../constants/Images';
const { width } = Dimensions.get('screen');

const Card = ({ item, full, style, ctaColor, imageStyle }) => {
  if (!item) return <Block row card flex style={[styles.card, style]}></Block>;

  const image =
    item.thumbnailUrl ||
    'https://tgilive.s3-ap-southeast-1.amazonaws.com/img/video.png';
  const name = item.name;
  const title = item.description;
  const author =
    (item.publisher && item.publisher.name) ||
    (item.author && item.author.name);
  const avatar =
    item.publisher && item.publisher.logo && item.publisher.logo.url;

  const imageStyles = [
    full ? styles.fullImage : styles.horizontalImage,
    imageStyle,
  ];
  const cardContainer = [styles.card, styles.shadow, style];
  const imgContainer = [styles.imageContainer, styles.shadow];

  return (
    <Block row card flex style={cardContainer}>
      <LinkOpacity to={`/video/${item.id}/${author}/${name}`}>
        <Block flex style={imgContainer}>
          <Image source={{ uri: image }} style={imageStyles} />
          <LinearGradient
            colors={['transparent', 'rgba(0,0,0,0.8)']}
            locations={[0.5, 1]}
            style={styles.overlayBackground}
          />
          <Block flex style={styles.overlay}>
            <Block flex style={styles.description}>
              <Text numberOfLines={3} ellipsizeMode="tail" style={styles.title}>
                {title}
              </Text>
            </Block>
            <Block flex row style={styles.publisher}>
              <Image
                defaultSource={Images.Avatar}
                source={{ uri: avatar }}
                style={styles.avatar}
              />
              <Block flex row center>
                <Text
                  numberOfLines={1}
                  ellipsizeMode="tail"
                  style={styles.author}
                >
                  {author}
                </Text>
              </Block>
            </Block>
          </Block>
          {item.status === 'live' && (
            <View style={styles.liveIndicator}>
              <Text style={styles.live}>LIVE</Text>
            </View>
          )}
        </Block>
      </LinkOpacity>
      {/* <TouchableWithoutFeedback
        onPress={() =>
          navigation.navigate('Livestream', { item })
        }
      >
        <Block flex space="between" style={styles.cardDescription}>
          {title && (
            <Text size={14} style={styles.cardTitle}>
              {title}
            </Text>
          )}
          {item.cta && (
            <Text
              size={12}
              muted={!ctaColor}
              color={ctaColor || argonTheme.COLORS.ACTIVE}
              bold
            >
              {item.cta}
            </Text>
          )}
        </Block>
      </TouchableWithoutFeedback> */}
    </Block>
  );
};

Card.propTypes = {
  item: PropTypes.object,
  full: PropTypes.bool,
  ctaColor: PropTypes.string,
  imageStyle: PropTypes.any,
};

const maxWidth = width >= 680 ? 340 : undefined;
const height =
  width >= 680 ? (340 / 3) * 4 : ((width - theme.SIZES.BASE * 3) / 2 / 3) * 4;

const styles = StyleSheet.create({
  card: {
    // width: width >= 680 ? 680 : (width - theme.SIZES.BASE * 3) / 2,
    maxWidth,
    // backgroundColor: theme.COLORS.WHITE,
    marginVertical: theme.SIZES.BASE,
    borderWidth: 0,
    minHeight: 114,
    marginBottom: 16,
  },
  cardTitle: {
    flex: 1,
    flexWrap: 'wrap',
    paddingBottom: 6,
  },
  cardDescription: {
    padding: theme.SIZES.BASE / 2,
  },
  imageContainer: {
    // borderRadius: 3,
    elevation: 1,
    overflow: 'hidden',
  },
  image: {
    // borderRadius: 3,
  },
  horizontalImage: {
    height,
    width: 'auto',
  },
  fullImage: {
    height: 215,
  },
  shadow: {
    shadowColor: theme.COLORS.BLACK,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 4,
    shadowOpacity: 0.1,
    elevation: 2,
  },
  overlayBackground: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    left: 10,
    right: 10,
  },
  description: {
    paddingBottom: 10,
  },
  title: {
    fontSize: 12,
    color: argonTheme.COLORS.WHITE,
    fontWeight: 'bold',
  },
  publisher: {
    paddingBottom: 10,
  },
  avatar: {
    marginRight: 5,
    width: 24,
    height: 24,
    borderRadius: 12,
  },
  author: {
    fontSize: 14,
    color: argonTheme.COLORS.WHITE,
    fontWeight: 'bold',
  },
  liveIndicator: {
    backgroundColor: 'rgb(240, 40, 74)',
    borderRadius: 4,
    position: 'absolute',
    top: 5,
    left: 5,
    paddingTop: 3,
    paddingRight: 6,
    paddingBottom: 3,
    paddingLeft: 6,
  },
  live: {
    fontSize: 12,
    color: argonTheme.COLORS.WHITE,
    fontWeight: 'bold',
  },
});

export default Card;
