import React, { useEffect, useState, useContext } from 'react';
import { Auth, Hub } from 'aws-amplify';

export const NOT_INSIDE_AMPLIFY_PROVIDER =
  'No AuthenticationContext. Did you forget to wrap your app in <AmplifyProvider />?';

/** Provides the entire Auth object via a hook to use it with hooks. Sugar. */
const AmplifyAuthContext = React.createContext(undefined);

/** Provides just the user object */
const UserContext = React.createContext(undefined);

/**
 * This is written specifically for Amplify/Hub, however, you can replace
 * this provider with another provider (say <AzureProvider />) that replicates
 * the API above and everything using useAuth and useUser should just work!
 */
export const AmplifyAuthProvider = ({ children }) => {
  // on mount, store the user and listen to hub changes (Amplify's internal)
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    async function getUser() {
      try {
        return await Auth.currentAuthenticatedUser();
      } catch {
        console.log('Not signed in');
      }
    }

    // bind hub listener for auth changes
    const listener = async ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          const userData = await getUser();
          setUser(userData);
          break;
        case 'signOut':
          setUser(null);
          break;
        case 'signIn_failure':
        case 'cognitoHostedUI_failure':
          console.log('Sign in failure', data);
          break;
      }
    };
    Hub.listen('auth', listener);

    // get user on mount with a immediately invoked function
    (async () => {
      const userData = await getUser();
      setUser(userData);
    })();

    // clean up hub listener
    return () => {
      Hub.remove('auth', listener);
    };
  }, []);

  return (
    <AmplifyAuthContext.Provider value={Auth}>
      <UserContext.Provider value={user}>{children}</UserContext.Provider>
    </AmplifyAuthContext.Provider>
  );
};

export function useAmplifyAuth() {
  const context = useContext(AmplifyAuthContext);
  if (!context) throw Error(NOT_INSIDE_AMPLIFY_PROVIDER);
  return context;
}

export function useAmplifyAuthProvider() {
  return {
    login: ({ username, password, provider }) =>
      username && password && !provider
        ? Auth.signIn(username, password)
        : Auth.federatedSignIn({ provider }),
    logout: () => Auth.signOut(),
    checkAuth: () => Auth.currentSession(),
    checkError: () => Auth.currentCredentials(),
    getPermissions: () =>
      Promise.all([Auth.currentSession(), Auth.currentCredentials()]).then(
        ([session, { identityId }]) => ({
          claims: { ...session.getIdToken().payload, identityId },
        })
      ),
  };
}

export function useAmplifyUser() {
  const context = useContext(UserContext);
  return context;
}
