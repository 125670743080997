import React, { useState } from 'react';
import { View } from 'react-native';
import { useLinkProps } from '@react-navigation/native';

export default ({ children, to, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { onPress, ...linkProps } = useLinkProps({ to });

  return (
    <View
      onClick={onPress}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        flex: 1,
        transitionDuration: '150ms',
        opacity: isHovered ? 0.8 : 1,
      }}
      {...linkProps}
      {...props}
    >
      {children}
    </View>
  );
};
