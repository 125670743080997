import { useEffect, useState } from 'react';
import { Cache, API, graphqlOperation } from 'aws-amplify';

import { useAmplifyUser } from '../lib/amplify';
import { getRegion } from '../lib/i18n';

const TGILIVE_FB_PAGE_ID = '100582988531529';
const LOADING_REFRESH_TIMEOUT = 300;

const config = {
  defaultTTL: 600000,
};
Cache.configure(config);

function useGraphQL() {
  const user = useAmplifyUser();

  async function graphql({ query, variables }) {
    return await API.graphql({
      query,
      variables,
      authMode: user ? 'AMAZON_COGNITO_USER_POOLS' : 'AWS_IAM',
    });
  }

  return graphql;
}

export function useCategories() {
  const user = useAmplifyUser();
  const graphql = useGraphQL();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [nextToken, setNextToken] = useState(null);

  useEffect(() => {
    refetch();
  }, [user]);

  async function fetchData({ nextToken, reset = false } = {}) {
    setLoading(true);
    let res;
    let items = [];
    let newToken;
    let newData = [];
    const cachedData = await Cache.getItem('categories');
    if (cachedData) {
      newToken = cachedData.nextToken;
      newData = cachedData.data;
    } else {
      try {
        const country = await getRegion();
        res = await graphql(
          graphqlOperation(categoriesByCountry, {
            country,
            sortDirection: 'ASC',
            nextToken,
          })
        );
        items = res?.data?.categoriesByCountry?.items || [];
        newToken = res?.data?.categoriesByCountry?.nextToken;
        newData = [...(reset ? [] : data), ...items];

        await Cache.setItem('categories', {
          data: newData,
          nextToken: newToken,
        });
      } catch (e) {
        console.log('Error fetching categories: ', e);
      }
    }
    setNextToken(newToken);
    setData(newData);
    setLoading(false);
    setLoaded(true);
  }

  async function fetch() {
    const timerId = setInterval(async () => {
      if (!loading) {
        clearInterval(timerId);
        if (!nextToken) return;
        await fetchData({ nextToken });
      }
    }, LOADING_REFRESH_TIMEOUT);
  }

  async function refetch() {
    const timerId = setInterval(async () => {
      if (!loading) {
        clearInterval(timerId);
        setNextToken(null);
        await fetchData({ reset: true });
      }
    }, LOADING_REFRESH_TIMEOUT);
  }

  return { data, loading, loaded, fetch, refetch };
}

export function useFbVideos({ categoryId, fbPageId }) {
  const user = useAmplifyUser();
  const graphql = useGraphQL();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [nextToken, setNextToken] = useState(null);

  useEffect(() => {
    refetch();
  }, [user, categoryId, fbPageId]);

  async function fetchData({ nextToken, reset = false } = {}) {
    setLoading(true);
    let res;
    let items = [];
    let newToken;
    let newData = [];
    try {
      const country = await getRegion();
      if (categoryId === 'all' || categoryId === 'live') {
        res = await graphql(
          graphqlOperation(fbVideosByFbPage, {
            fbPageId: TGILIVE_FB_PAGE_ID,
            sortDirection: 'DESC',
            filter: { status: { eq: 'live' } },
          })
        );
        items = res?.data?.fbVideosByFbPage?.items || [];
        newData = [...(reset ? [] : data), ...items];

        res = await graphql(
          graphqlOperation(fbVideosByCountryByStatus, {
            country,
            sortDirection: 'DESC',
            filter: { fbPageId: { ne: TGILIVE_FB_PAGE_ID } },
            statusDatePublished:
              categoryId === 'live'
                ? { beginsWith: { status: 'live' } }
                : { ge: { status: 'inactive' } },
            limit: 20,
            nextToken,
          })
        );
        items = res?.data?.fbVideosByCountryByStatus?.items || [];
        newToken = res?.data?.fbVideosByCountryByStatus?.nextToken;
        newData = [...newData, ...items];
      } else if (categoryId) {
        res = await graphql(
          graphqlOperation(fbVideoCategoryJoinsByCountryCategoryByStatus, {
            countryCategoryId: `${country}:${categoryId}`,
            sortDirection: 'DESC',
            statusDatePublished: { ge: { status: 'inactive' } },
            limit: 20,
            nextToken,
          })
        );
        items = res?.data?.fbVideoCategoryJoinsByCountryCategoryByStatus?.items
          ? res?.data?.fbVideoCategoryJoinsByCountryCategoryByStatus?.items.map(
              (item) => item.fbVideo
            )
          : [];
        newToken = res?.data?.fbVideoCategoryJoinsByCountryCategory?.nextToken;
        newData = [...(reset ? [] : data), ...items];
      } else if (fbPageId) {
        res = await graphql(
          graphqlOperation(fbVideosByFbPage, {
            fbPageId,
            sortDirection: 'DESC',
            limit: 20,
            nextToken,
          })
        );
        items = res?.data?.fbVideosByFbPage?.items
          ? res?.data?.fbVideosByFbPage?.items
          : [];
        newToken = res?.data?.fbVideosByFbPage?.nextToken;
        newData = [...(reset ? [] : data), ...items];
      }
    } catch (e) {
      console.log('Error fetching videos: ', e);
    }
    setNextToken(newToken);
    setData(newData);
    setLoading(false);
    setLoaded(true);
  }

  async function fetch() {
    const timerId = setInterval(async () => {
      if (!loading) {
        clearInterval(timerId);
        if (!nextToken) return;
        await fetchData({ nextToken });
      }
    }, LOADING_REFRESH_TIMEOUT);
  }

  async function refetch() {
    const timerId = setInterval(async () => {
      if (!loading) {
        clearInterval(timerId);
        setNextToken(null);
        await fetchData({ reset: true });
      }
    }, LOADING_REFRESH_TIMEOUT);
  }

  return { data, loading, loaded, fetch, refetch };
}

export function useFbVideo({ id }) {
  const user = useAmplifyUser();
  const graphql = useGraphQL();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    fetch();
  }, [user, id]);

  async function fetchData() {
    setLoading(true);
    let res;
    let newData;
    try {
      res = await graphql(graphqlOperation(getFbVideo, { id }));
      newData = res?.data?.getFbVideo;
    } catch (e) {
      console.log('Error fetching video: ', e);
    }
    setData(newData);
    setLoading(false);
    setLoaded(true);
  }

  async function fetch() {
    const timerId = setInterval(async () => {
      if (!loading) {
        clearInterval(timerId);
        await fetchData();
      }
    }, LOADING_REFRESH_TIMEOUT);
  }

  return { data, loading, loaded, fetch };
}

export function useFbPage({ id }) {
  const user = useAmplifyUser();
  const graphql = useGraphQL();

  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    fetch();
  }, [user, id]);

  async function fetchData() {
    setLoading(true);
    let res;
    let newData;
    try {
      res = await graphql(graphqlOperation(getFbPage, { id }));
      newData = res?.data?.getFbPage;
    } catch (e) {
      console.log('Error fetching page: ', e);
    }
    setData(newData);
    setLoading(false);
    setLoaded(true);
  }

  async function fetch() {
    const timerId = setInterval(async () => {
      if (!loading) {
        clearInterval(timerId);
        await fetchData();
      }
    }, LOADING_REFRESH_TIMEOUT);
  }

  return { data, loading, loaded, fetch };
}

export function useFbPages() {
  const user = useAmplifyUser();
  const graphql = useGraphQL();

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [nextToken, setNextToken] = useState(null);

  useEffect(() => {
    refetch();
  }, [user]);

  async function fetchData({ nextToken, reset = false } = {}) {
    setLoading(true);
    let res;
    let items = [];
    let newToken;
    let newData = [];
    try {
      const country = await getRegion();
      res = await graphql(
        graphqlOperation(fbPagesByCountry, {
          country,
          sortDirection: 'ASC',
          limit: 20,
          nextToken,
        })
      );
      items = res?.data?.fbPagesByCountry?.items
        ? res?.data?.fbPagesByCountry?.items
        : [];
      newToken = res?.data?.fbPagesByCountry?.nextToken;
      newData = [...(reset ? [] : data), ...items];
    } catch (e) {
      console.log('Error fetching pages: ', e);
    }
    setNextToken(newToken);
    setData(newData);
    setLoading(false);
    setLoaded(true);
  }

  async function fetch() {
    const timerId = setInterval(async () => {
      if (!loading) {
        clearInterval(timerId);
        if (!nextToken) return;
        await fetchData({ nextToken });
      }
    }, LOADING_REFRESH_TIMEOUT);
  }

  async function refetch() {
    const timerId = setInterval(async () => {
      if (!loading) {
        clearInterval(timerId);
        setNextToken(null);
        await fetchData({ reset: true });
      }
    }, LOADING_REFRESH_TIMEOUT);
  }

  return { data, loading, loaded, fetch, refetch };
}

const categoriesByCountry = /* GraphQL */ `
  query CategoriesByCountry(
    $country: String
    $order: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoriesByCountry(
      country: $country
      order: $order
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        country
        order
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

const fbVideoCategoryJoinsByCountryCategoryByStatus = /* GraphQL */ `
  query FbVideoCategoryJoinsByCountryCategoryByStatus(
    $countryCategoryId: String
    $statusDatePublished: ModelFbVideoCategoryJoinFbVideoCategoryJoinsByCountryCategoryByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFbVideoCategoryJoinFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fbVideoCategoryJoinsByCountryCategoryByStatus(
      countryCategoryId: $countryCategoryId
      statusDatePublished: $statusDatePublished
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fbVideo {
          id
          fbPageId
          country
          name
          description
          thumbnailUrl
          duration
          uploadDate
          contentUrl
          interactionCount
          publication {
            isLiveBroadcast
            startDate
            endDate
          }
          url
          datePublished
          width
          height
          thumbnail {
            contentUrl
            width
            height
          }
          headline
          isFamilyFriendly
          genre
          keywords
          commentCount
          comment {
            text
            upvoteCount
          }
          inLanguage
          contentSize
          videoQuality
          author {
            name
            url
          }
          publisher {
            logo {
              url
            }
            name
            url
          }
          about
          videoMimeType
          embedUrl
          live
          status
        }
      }
      nextToken
    }
  }
`;

const fbVideosByCountryByStatus = /* GraphQL */ `
  query FbVideosByCountryByStatus(
    $country: String
    $statusDatePublished: ModelFbVideoFbVideosByCountryByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFbVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fbVideosByCountryByStatus(
      country: $country
      statusDatePublished: $statusDatePublished
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fbPageId
        country
        name
        description
        thumbnailUrl
        duration
        uploadDate
        contentUrl
        interactionCount
        publication {
          isLiveBroadcast
          startDate
          endDate
        }
        url
        datePublished
        width
        height
        thumbnail {
          contentUrl
          width
          height
        }
        headline
        isFamilyFriendly
        genre
        keywords
        commentCount
        comment {
          text
          upvoteCount
        }
        inLanguage
        contentSize
        videoQuality
        author {
          name
          url
        }
        publisher {
          logo {
            url
          }
          name
          url
        }
        about
        videoMimeType
        embedUrl
        live
        status
        categories
        derivedCategories
        actualCategories
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

const fbVideosByFbPage = /* GraphQL */ `
  query FbVideosByFbPage(
    $fbPageId: ID
    $datePublished: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFbVideoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fbVideosByFbPage(
      fbPageId: $fbPageId
      datePublished: $datePublished
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        fbPageId
        country
        name
        description
        thumbnailUrl
        duration
        uploadDate
        contentUrl
        interactionCount
        publication {
          isLiveBroadcast
          startDate
          endDate
        }
        url
        datePublished
        width
        height
        thumbnail {
          contentUrl
          width
          height
        }
        headline
        isFamilyFriendly
        genre
        keywords
        commentCount
        comment {
          text
          upvoteCount
        }
        inLanguage
        contentSize
        videoQuality
        author {
          name
          url
        }
        publisher {
          logo {
            url
          }
          name
          url
        }
        about
        videoMimeType
        embedUrl
        live
        status
        categories
        derivedCategories
        actualCategories
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

const getFbVideo = /* GraphQL */ `
  query GetFbVideo($id: ID!) {
    getFbVideo(id: $id) {
      id
      fbPageId
      country
      name
      description
      thumbnailUrl
      duration
      uploadDate
      contentUrl
      interactionCount
      publication {
        isLiveBroadcast
        startDate
        endDate
      }
      url
      datePublished
      width
      height
      thumbnail {
        contentUrl
        width
        height
      }
      headline
      isFamilyFriendly
      genre
      keywords
      commentCount
      comment {
        text
        upvoteCount
      }
      inLanguage
      contentSize
      videoQuality
      author {
        name
        url
      }
      publisher {
        logo {
          url
        }
        name
        url
      }
      about
      videoMimeType
      embedUrl
      live
      status
      categories
      derivedCategories
      actualCategories
      createdAt
      updatedAt
    }
  }
`;

const getFbPage = /* GraphQL */ `
  query GetFbPage($id: ID!) {
    getFbPage(id: $id) {
      id
      slug
      country
      name
      description
      image
      url
      address {
        streetAddress
        addressLocality
        addressRegion
        postalCode
      }
      aggregateRating {
        ratingValue
        ratingCount
      }
      priceRange
      telephone
      affiliation {
        name
      }
      categories
      createdAt
      updatedAt
    }
  }
`;

const fbPagesByCountry = /* GraphQL */ `
  query FbPagesByCountry(
    $country: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelFbPageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    fbPagesByCountry(
      country: $country
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        slug
        country
        name
        description
        image
        url
        address {
          streetAddress
          addressLocality
          addressRegion
          postalCode
        }
        aggregateRating {
          ratingValue
          ratingCount
        }
        priceRange
        telephone
        affiliation {
          name
        }
        categories
        createdAt
        updatedAt
        fbVideos {
          nextToken
        }
      }
      nextToken
    }
  }
`;
